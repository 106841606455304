var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex flex-col items-center rounded-md border bg-white p-4 hover:cursor-pointer",class:{
    'border-coolrunner-500 ring-1 ring-coolrunner-500': _vm.selected,
    'border-gray-200': !_vm.selected,
    'h-[120px] justify-between': !_vm.manual,
    'h-[100px] justify-center': _vm.manual,
  },on:{"click":function($event){return _vm.$emit('select')}}},[_c('div',{staticClass:"relative w-full text-center",class:{
      'h-12': !_vm.manual,
      'h-6': _vm.manual,
    }},[_c('img',{staticClass:"absolute h-full w-full object-contain",attrs:{"src":_vm.type.image,"alt":_vm.title}})]),(!_vm.manual)?_c('div',{staticClass:"text-center text-sm font-semibold leading-tight text-zinc-800"},[_vm._v(_vm._s(_vm.title || _vm.type.title))]):_vm._e(),(_vm.selected)?_c('div',{staticClass:"absolute left-0 top-0 h-9 w-9 rounded-br-lg bg-coolrunner-500 p-2"},[_c('div',{staticClass:"relative h-5 w-5"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticStyle:{"fill":"white","fill-opacity":"1"},attrs:{"d":"M18.1632 6.28815L8.16325 16.2882C8.07615 16.3756 7.97266 16.4449 7.8587 16.4922C7.74475 16.5395 7.62257 16.5639 7.49918 16.5639C7.3758 16.5639 7.25362 16.5395 7.13967 16.4922C7.02571 16.4449 6.92222 16.3756 6.83512 16.2882L2.46012 11.9132C2.37292 11.8259 2.30374 11.7224 2.25655 11.6085C2.20935 11.4945 2.18506 11.3724 2.18506 11.2491C2.18506 11.1258 2.20935 11.0036 2.25655 10.8897C2.30374 10.7758 2.37292 10.6722 2.46012 10.585C2.54733 10.4978 2.65086 10.4286 2.7648 10.3814C2.87874 10.3343 3.00086 10.31 3.12418 10.31C3.24751 10.31 3.36963 10.3343 3.48357 10.3814C3.59751 10.4286 3.70104 10.4978 3.78825 10.585L7.49997 14.2967L16.8367 4.96159C17.0128 4.78547 17.2517 4.68652 17.5007 4.68652C17.7498 4.68652 17.9887 4.78547 18.1648 4.96159C18.3409 5.13771 18.4399 5.37658 18.4399 5.62565C18.4399 5.87472 18.3409 6.11359 18.1648 6.28971L18.1632 6.28815Z","fill":"white"}})])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }