<template>
  <div>
    <BackButton @click="() =>$emit('back')" />
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.step_3.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.step_3.description") }}</div>
    <div class="my-8 flex flex-col gap-8">
      <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3">
        <ShopPicker v-for="sp in shopifyPlans" :key="sp" :type="shopTypes['shopify']" :selected="form.shopifyPlan === sp" class="col-span-1" :title="$t(`shopify_plan.${sp}`)" @select="form.shopifyPlan = sp" />
      </div>
    </div>

    <div v-if="form.shopifyPlan == 'shopify'" class="mb-8 inline-flex w-full flex-col items-center justify-start gap-3">
      <div class="self-stretch text-base font-normal text-zinc-500">{{ $t("payment_frequency") }}</div>
      <div class="inline-flex items-start justify-center gap-6 self-stretch">
        <PlanPicker :title="$t('payment_frequency_monthly')" size="small" :selected="form.paymentFrequency === 'monthly'" @click="form.paymentFrequency = 'monthly'" />
        <PlanPicker :title="$t('payment_frequency_yearly')" size="small" :selected="form.paymentFrequency === 'yearly'" @click="form.paymentFrequency = 'yearly'" />
      </div>
    </div>

    <div class="mb-8 inline-flex items-start justify-center gap-2 rounded-md border border-gray-200 bg-[#F9F9F9] p-2">
      <div class="text-base font-semibold leading-normal text-black">💡</div>
      <div class="flex-col items-start justify-start gap-0.5">
        <div class="mb-1 text-sm font-semibold leading-tight text-zinc-800">Shopify Basic</div>
        <div class="text-sm font-normal leading-5 text-zinc-500">
          {{ $t("flow.shop.step_3.shopify_basic_info") }}
        </div>
      </div>
    </div>

    <CoreButton type="coolrunner" class="w-full" :disabled="buttonDisabled" @click="continueForm">{{ buttonTitle }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import ShopPicker from "./components/ShopPicker.vue";
import PlanPicker from "../Plan/components/PlanPicker.vue";
import BackButton from "../components/BackButton.vue";
export default {
  name: "ShopStep3",
  components: {
    CoreButton,
    BackButton,
    ShopPicker,
    PlanPicker,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    shopifyPlans: ["basic", "shopify", "advanced"],
  }),
  computed: {
    buttonTitle: function () {
      let title = this.$t("continue");

      if (this.form.shopifyPlan) {
        title = `${this.$t("continue_with")} ${this.$t(`shopify_plan.${this.form.shopifyPlan}`)}`;
      }

      if (this.form.paymentFrequency) {
        title += ` - ${this.$t(`payment_frequency_${this.form.paymentFrequency}`)}`;
      }

      return title;
    },
    buttonDisabled: function () {
      if (!this.form.shopifyPlan) return true;

      if (this.form.shopifyPlan === "advanced" || this.form.shopifyPlan === 'basic') return false;
      else {
        if (this.form.paymentFrequency) {
          return false
        } else {
          return true;
        }
      }
    },
  },
  watch: {
    shopifyPlan: function (val) {
        this.form.paymentFrequency = null;
    },
  },
  methods: {
    continueForm: function () {
      if (this.form.shopifyPlan === "advanced") this.$emit("continue", 'ActivateCarrier');
      else if (this.form.shopifyPlan === "shopify") {
        if (this.form.paymentFrequency == "yearly") {
          this.$emit("continue", 'ActivateCarrier');
        } else {
          this.$emit("continue", 'ShopifyNoCCS');
        }
      } else {
        this.$emit("continue", 'ShopifyNoCCS');
      }
    },
  },
};
</script>
