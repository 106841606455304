<template>
  <div>
    <BackButton @click="() => $emit('back')" />
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.activate_carriers.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.activate_carriers.description") }}</div>

    <div class="mb-6 mt-8 flex flex-col gap-8">
      <div class="inline-flex flex-col items-start justify-start gap-6 rounded-md border border-gray-200 bg-white p-6">
        <Input v-model="form.freeFreightLimit" :label="$t('flow.shop.free_freight.threshold')" />

        <div class="flex flex-col items-start justify-start gap-3 self-stretch">
          <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">{{ $t("flow.shop.free_freight.activate") }}</div>
          <div class="flex flex-col items-stretch gap-2 self-stretch">
            <template v-for="carrier in carriers">
              <CarrierPicker v-for="cps in carrier.cps" :key="cps.slug" :carrier="mapCps(carrier, cps)" :active="isFreeCps(cps)" @click="() => selectCps(cps)" />
            </template>
          </div>
        </div>
      </div>
    </div>

    <CoreButton type="coolrunner" class="w-full" @click="continueForm">{{ `${this.$t("continue")}` }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import BackButton from "../components/BackButton.vue";
import CarrierPicker from "./components/CarrierPicker.vue";
import Input from "@/components/core/Input.vue";

export default {
  name: "ActivateCarrier",
  components: {
    CoreButton,
    BackButton,
    CarrierPicker,
    Input,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopTypes: {
      type: Object,
      default: () => ({}),
    },
    carriers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    defaultPrice: 49,
  }),
  computed: {
    possibleCarrierCpsCombinations: function () {
      return this.carriers.map;
    },
  },

  methods: {
    continueForm: function () {
      let shippingMethods = [];
      this.form.cps.forEach((cps) => {
        shippingMethods.push(this.buildShippingMethodFromCps(cps));
      });

      this.form.shippingMethods = shippingMethods;

      this.$emit("continue", "Review");
    },
    isFreeCps: function (cps) {
      return this.form.freeCps.map(c => c.slug).includes(cps.slug);
    },
    selectCps: function (cps) {
      if (this.isFreeCps(cps)) {
        this.form.freeCps = this.form.freeCps.filter((item) => item.slug !== cps.slug);
      } else {
        this.form.freeCps.push(cps);
      }
    },
    mapCps: function (carrier, cps) {
      return {
        ...carrier,
        name: cps.name,
      };
    },
    buildShippingMethodFromCps: function (cps) {
      let slugSplits = cps.slug.split("_");
      let carrierSlug = slugSplits[0];

      return {
        address_validation: false,
        name: cps.name,
        description: cps.name,
        logo: null,
        carrier: carrierSlug,
        carrier_product_service: 
        {
          carrier: carrierSlug,
          slug: cps.slug,
          name: cps.name,
        },
        rules: this.buildRules(cps),
        options: {},
      };
    },
 
  buildRules: function (cps) {
    let rules = [];
    const isFreeCps = this.isFreeCps(cps);
    rules.push({
      priority: 1,
      filters: isFreeCps
        ? {
            cart_subtotal: [
              {
                value: {
                  lte: this.form.freeFreightLimit - 0.01,
                },
                inverse: false,
              },
            ],
          }
        : {},
      price: this.defaultPrice,
      description: "",
    });

    if (isFreeCps)
      rules.push({
        priority: 2,
        filters: {
          cart_subtotal: [
            {
              value: {
                gte: this.form.freeFreightLimit,
              },
              inverse: false,
            },
          ],
        },
        price: 0,
      });

      return rules;
  },
}
};
</script>

/* let shippingMethod = { "address_validation": false, "name": "", "description": "", "logo": null, "carrier": "", "carrier_product_service": { "carrier": "bring", "slug": "bring_private_delivery", "name": "Bring Hjemmelevering ( bring_private_delivery )" }, "rules": [ { "priority": 1, "filters": {
"cart_subtotal": [ { "value": { "lte": 499.99 }, "inverse": false } ] }, "price": "10", "description": "" }, { "priority": 2, "filters": { "cart_subtotal": [ { "value": { "gte": 500 }, "inverse": false } ] }, "price": "0" } ], "options": { } } */
