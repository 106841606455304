var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BackButton',{on:{"click":() =>_vm.$emit('back')}}),_c('div',{staticClass:"mt-2 text-3xl font-semibold"},[_vm._v(_vm._s(_vm.$t("flow.shop.step_2.title")))]),_c('div',{staticClass:"text-base font-normal leading-normal",staticStyle:{"color":"#6d7276"}},[_vm._v(_vm._s(_vm.$t("flow.shop.step_2.description")))]),_c('div',{staticClass:"my-8 flex flex-col gap-8"},[_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-3"},_vm._l((Object.entries(_vm.shopTypes)),function([key, st],idx){return _c('ShopPicker',{key:key,staticClass:"col-span-2",class:{
        'col-start-2': idx === 3,
        'col-start-4': idx === 4,
      },attrs:{"type":st,"selected":_vm.form.type === key},on:{"select":function($event){_vm.form.type = key}}})}),1),_c('div',{staticClass:"flex w-full flex-col gap-3"},[_c('div',{staticClass:"self-stretch text-center text-sm font-normal leading-tight text-zinc-500"},[_vm._v(_vm._s(_vm.$t("flow.shop.step_2.manually_configure")))]),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-3 self-stretch"},_vm._l((Object.entries(_vm.manualShopTypes)),function([key, st],idx){return _c('ShopPicker',{key:key,staticClass:"col-span-2",class:{
        'col-start-2': idx === 3,
        'col-start-4': idx === 4,
      },attrs:{"manual":"","type":st,"selected":_vm.form.type === key},on:{"select":function($event){_vm.form.type = key}}})}),1)])]),_c('CoreButton',{staticClass:"w-full",attrs:{"type":"coolrunner","disabled":!_vm.form.type},on:{"click":_vm.continueForm}},[_vm._v(_vm._s(_vm.$t("continue")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }