<template>
  <div>
    <div class="text-3xl font-semibold">{{ $t("flow.plan.step_1.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.plan.step_1.description") }}</div>
    <div class="mt-8 form-plan-bg" :class="[form.plan]" />
    <div class="my-4 xs:my-8 flex w-full items-start justify-start gap-3 xs:gap-6 flex-col xs:flex-row">
      <PlanPicker :selected="form.plan == 'hand_in'" :title="$t('flow.plan.step_1.hand_in.box.title')" :description="$t('flow.plan.step_1.hand_in.box.description')" @click="form.plan = 'hand_in'" />
      <PlanPicker :selected="form.plan == 'pick_up'" :title="$t('flow.plan.step_1.pick_up.box.title')" :description="$t('flow.plan.step_1.pick_up.box.description')" @click="form.plan = 'pick_up'" />
    </div>
    <div>
      <div class="inline-flex h-5 items-center justify-center gap-2.5 rounded-full bg-orange-300 px-2 py-1">
        <div class="text-xs font-medium leading-3 text-zinc-800">{{ $t(`${planLocaliationPrefix}.tagline`) }}</div>
      </div>
      <div class="mt-1 xs:mt-4 text-2xl font-semibold text-zinc-800">{{ $t(`${planLocaliationPrefix}.title`) }}</div>
      <div class="mt-1.5 xs:mt-3 flex flex-col gap-1 xs:gap-2">
        <div v-for="check in planDescriptions.checkmarks" :key="check" class="inline-flex gap-2">
          <div class="flow-checkmark--green h-5 w-5"></div>
          <div class="w-full text-sm font-normal leading-tight text-zinc-800">{{ check }}</div>
        </div>
      </div>

      <div class="my-4 flex w-full flex-col items-start justify-start gap-2 rounded-md border border-zinc-300 bg-white p-3">
        <template v-for="(row, index) in planTable">
          <div :key="`${form.plan}_${row.title}`" class="plan__table__row">
            <div class="plan__table__header">{{ row.title }}</div>
            <div class="plan__table__value">
              <div class="text-center text-sm font-normal leading-tight text-zinc-500">{{ row.value }}</div>
              <popper v-if="row.info" trigger="hover" :options="{ placement: 'right' }">
                <div class="flow-popper popper" v-html="row.info">
                </div>
                <div slot="reference" class="flow-info relative h-4 w-4"></div>
              </popper>
            </div>
          </div>
          <div v-if="index !== planTable.length - 1" :key="`${form.plan}_${index}_seperator`" class="h-px w-full bg-zinc-300"></div>
        </template>
      </div>
    </div>

    <CoreButton type="coolrunner" class="w-full" @click="submit">{{ $t("continue_with") }} {{ $t(`flow.plan.step_1.${form.plan}.box.title`) }}</CoreButton>
    <div class="mt-3 text-xs font-normal leading-none tracking-tight text-zinc-500">{{ $t("flow.plan.step_1.disclaimer") }}</div>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import PlanPicker from "./components/PlanPicker.vue";
import da from "@/locales/da.json";
import en from "@/locales/en.json";
import Popper from "vue-popperjs";

export default {
  name: "PlanStep1",
  components: {
    CoreButton,
    PlanPicker,
    Popper,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    planLocaliationPrefix() {
      if (this.form.plan == "hand_in") return "flow.plan.step_1.hand_in.description";
      else return "flow.plan.step_1.pick_up.description";
    },
    planDescriptions() {
      if (this.$i18n.locale == "da") return this.$_.get(da, this.planLocaliationPrefix);
      else return this.$_.get(en, this.planLocaliationPrefix);
    },
    pickupTable() {
      return [
        {
          title: this.$t("flow.plan.step_1.pick_up.excl_fuel"),
          value: "fra 75 kr.",
          info: "Brændstoftillæg beregnes hver måned. Fra den 15. i hver måned, kan næste månedstillæg ses på dette <a class='underline' style='font-weight: bold' target='_blank' href=https://www.fragt.dk/nyheder/maanedens-olietillaeg/>link</a>",
        },
        {
          title: this.$t("flow.plan.step_1.pick_up.time"),
          value: "kl. 14.00-16:00",
        },
        {
          title: this.$t("flow.plan.step_1.package_price_incl_fee"),
          value: "fra 23,40 kr.",
        },
        {
          title: this.$t("flow.plan.step_1.automatic_balance"),
          value: this.$t("flow.required"),
          info: this.$t("flow.plan.automatic_balance_info"),
        },
        {
          title: this.$t("flow.plan.step_1.geographical_coverage"),
          value: "EU",
          info: this.$t("flow.plan.step_1.geographical_coverage_info")
        },
      ];
    },
    handInTable() {
      return [
        {
          title: this.$t("flow.plan.step_1.hand_in.box.title"),
          value: "0 kr.",
        },
        {
          title: this.$t("flow.plan.step_1.package_price_incl_fee"),
          value: "fra 27,70 kr.",
        },
        {
          title: this.$t("flow.plan.step_1.automatic_balance"),
          value: this.$t("flow.optional"),
          info: this.$t("flow.plan.automatic_balance_info"),
        },
        {
          title: this.$t("flow.plan.step_1.geographical_coverage"),
          value: "EU",
          info: this.$t("flow.plan.step_1.geographical_coverage_info")
        },
      ];
    },
    planTable: function () {
      if (this.form.plan == "hand_in") return this.handInTable;
      else return this.pickupTable;
    },
  },
  methods: {
    submit: function () {
      if(this.form.plan == "hand_in")
        this.$emit("continue", 2);
      else
        this.$emit("continue", 1);
    },
  },
};
</script>

<style>
.plan__table__header {
  @apply text-left text-sm font-normal leading-tight text-zinc-500;
}
.plan__table__value {
  @apply flex items-center justify-start gap-1;
}

.plan__table__row {
  @apply inline-flex w-full justify-between;
}

.form-plan-bg {
  @apply bg-contain;
  aspect-ratio: 3/1;
}

.form-plan-bg.hand_in {
  background-image: url('/img/flows/plan/step1_hand_in.png');
}

.form-plan-bg.pick_up {
  background-image: url('/img/flows/plan/step1_pick_up.png');
}
</style>
