<template>
  <div class="inline-flex gap-6">
    <div class="inline-flex h-[50px] w-[50px] flex-col items-center justify-center rounded-full border border-gray-200" style="flex-shrink: 0">
      <div class="text-2xl font-light text-gray-500">{{ number }}</div>
    </div>
    <div class="flex flex-col items-start gap-1 self-stretch" :class="{'justify-center': !$slots.default}">
      <div class="text-base font-medium leading-normal text-zinc-800">{{ title }}</div>
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
