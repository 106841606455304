<template>
  <div>
    <div class="inline-flex h-8 items-center justify-center gap-1 rounded-md border border-gray-300 bg-white px-3 hover:cursor-pointer hover:bg-gray-200" @click="() => $emit('back')">
      <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t("flow.go_back") }}</div>
    </div>
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.plan.step_2.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.plan.step_2.description") }}</div>
    <div class="my-6 flex flex-col gap-4">
      <UnitPicker
        v-for="unit in unitTypes"
        :key="unit.type"
        :type="unit.type"
        :title="unit.title"
        :subtitle="unit.subtitle"
        :description="unit.description"
        :loading-instruction-link="unit.instruction"
        :selected="unit.type === form.unit_type"
        :dimensions="unit.dimensions"
        @select="() => (form.unit_type = unit.type)"
      >
        <template #prices>
          <template v-if="unit.type === 'bag'">
            <div class="flex w-full flex-col items-stretch justify-start gap-1">
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">Pris pr. sæk</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">25,00 kr.</div>
              </div>
            </div>
            <div class="flex h-5 flex-col items-start justify-start gap-1">
              <div class="inline-flex items-center justify-start gap-1">
                <div class="text-sm font-normal leading-tight text-blue-600 hover:text-blue-800" @click="showAutomaticDeliveryModal = true">{{ $t("flow.plan.step_2.bag.automatic_delivery.title") }}</div>
              </div>
            </div>
            <div class="inline-flex items-center justify-start gap-1">
              <div class="text-xs font-normal leading-[17.40px] text-zinc-500">Mindstepris for afhentning er 75,00 kr.</div>
              <div class="relative h-4 w-4"></div>
            </div>
          </template>

          <template v-else-if="unit.type === 'pallet'">
            <div class="flex w-full flex-col items-stretch justify-start gap-1">
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">1 palle</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">75,00 kr. pr. palle</div>
              </div>
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">2-3 paller</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">65,00 kr. pr. palle</div>
              </div>
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">4-6 paller</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">55,00 kr. pr. palle</div>
              </div>
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">7-10 paller</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">45,00 kr. pr. palle</div>
              </div>
              <div class="flex justify-between gap-3">
                <div class="text-sm font-normal leading-tight text-zinc-800">11+ paller</div>
                <div class="text-right text-sm font-normal leading-tight text-zinc-800">40,00 kr. pr. palle</div>
              </div>
            </div>
          </template>
        </template>
      </UnitPicker>
    </div>

    <CoreButton type="coolrunner" class="w-full" @click="submit">{{ $t("continue_with") }} {{ $t(`flow.plan.step_2.${form.unit_type}.title`) }}</CoreButton>
    <Modal v-if="showAutomaticDeliveryModal" :title="$t('flow.plan.step_2.bag.automatic_delivery.title')" :content="$t('flow.plan.step_2.bag.automatic_delivery.modal.content')" @close="showAutomaticDeliveryModal = false" />
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import UnitPicker from "./components/UnitPicker.vue";
import Modal from "./components/Modal.vue";
export default {
  name: "PlanStep2",
  components: {
    CoreButton,
    UnitPicker,
    Modal,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showAutomaticDeliveryModal: false,
  }),
  computed: {
    unitTypes: function () {
      return [
        {
          type: "bag",
          title: this.$t("flow.plan.step_2.bag.title"),
          subtitle: this.$t("flow.plan.step_2.bag.subtitle"),
          description: this.$t("flow.plan.step_2.bag.description"),
          instruction: "https://homerunner.com/guides/saekke-guide/",
          dimensions: {
            dimensions: "130 x 85 cm",
            weight: "Max 30 kg",
            load_capacity: "Op til 120 L",
          },
        },
        {
          type: "pallet",
          title: this.$t("flow.plan.step_2.pallet.title"),
          subtitle: this.$t("flow.plan.step_2.pallet.subtitle"),
          description: this.$t("flow.plan.step_2.pallet.description"),
          instruction: "https://homerunner.com/guides/pallet-guide/",
          dimensions: {
            dimensions: "120 x 80 cm",
            weight: "Max 600 kg",
            height: "Max 220 cm",
          },
        },
      ];
    },
  },
  methods: {
    submit: function () {
      this.$emit("continue", 2);
    },
  },
};
</script>
