<template>
  <div>
    <div class="text-3xl font-semibold">{{ $t("flow.create_user.step_1.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.create_user.step_1.description") }}</div>
    <div class="mt-8 flex flex-col gap-3">
      <Input v-model="form.name" :label="$t('name')" autocomplete="name" />
      <Input v-model="form.phone" :label="$t('phone')" autocomplete="phone" />
      <Input v-model="form.email" :label="$t('email')" autocomplete="email" :error="!!errors.email" @blur="checkEmail"/>
      <Input v-model="form.password" :label="$t('password')" type="password"  autocomplete="new-password" @blur="checkPassword" />
      <Input v-model="form.password_confirmation" :label="$t('repeat_password')" type="password" autocomplete="new-password" :error="!!errors.password_confirmation" @blur="checkPassword"/>
    </div>
    <div class="mb-6 mt-6">
      <CoreCheckbox :value="form.toc" @change="(val) => (form.toc = val)">
        <i18n path="flow.create_user.step_1.terms" tag="p">
          <template v-slot:terms>
            <a href="https://homerunner.com/handelsbetingelser/" target="_blank">{{ $t("flow.create_user.step_1.sales_terms").toLowerCase() }}</a>
          </template>
        </i18n>
      </CoreCheckbox>
    </div>

    <CoreButton class="w-full" type="coolrunner" :disabled="!canContinue" @click="submit">{{ $t("continue") }}</CoreButton>

  </div>
</template>

<script>
import Input from "@/components/core/Input.vue";
import CoreCheckbox from "@/components/core/Checkbox.vue";
import CoreButton from "@/components/core/Button.vue";

export default {
  name: "CreateUserStep1",
  components: {
    Input,
    CoreCheckbox,
    CoreButton,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errors: {
      email: '',
      password_confirmation: '',
    }
  }),
  computed: {
    canContinue: function () {
      const passwordsMatch = this.form.password === this.form.password_confirmation;

      return this.form.toc && this.form.name && this.form.phone && this.form.email && this.form.password && this.form.password_confirmation && passwordsMatch;
    },
    emailIsValid: function () {
      //validate this.form.email is a valid url using regex

      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;

      return pattern.test(this.form.email);
    }
  },
  watch: {
    'form.password': function () {
      this.errors.password_confirmation = '';
    },
    'form.password_confirmation': function () {
      this.errors.password_confirmation = '';
    },
    'form.email': function () {
      this.errors.email = '';
    },
  },
  methods: {
    submit: function () {
      this.$emit("continue");
    },
    checkEmail: function () {

      if(!this.emailIsValid && this.form.email && !this.errors.email) {
        this.errors.email = '';
        this.$nextTick(() => {
          this.errors.email = this.$t('errors.email_not_valid');
        })
      }
      else {
        this.errors.email = '';
      }
    },
    checkPassword: function () {
      if(!this.form.password || !this.form.password_confirmation || this.errors.password_confirmation) {
        this.errors.password_confirmation = '';
        return;
      };

      if(this.form.password !== this.form.password_confirmation) {
        this.errors.password_confirmation = '';
        this.$nextTick(() => {
          this.errors.password_confirmation = this.$t('errors.passwords_not_matching');
        })
      }
      else {
        this.errors.password_confirmation = '';
      }
    },
  }
};
</script>
