<template>
  <dialog ref="dialog" class="z-0 rounded-xl m-auto" style="max-width: 480px" @close="emitClose">
    <div class="w-full grow-0 items-center justify-between px-6 py-3 flex  bg-white">
      <div class="w-full text-base font-semibold leading-4 text-zinc-800 text-pre-line">{{ title }}</div>
      <div class="h-4 w-4 hover:cursor-pointer" @click="close">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.0306 12.4695C13.1715 12.6104 13.2506 12.8015 13.2506 13.0007C13.2506 13.2 13.1715 13.3911 13.0306 13.532C12.8897 13.6729 12.6986 13.752 12.4993 13.752C12.3001 13.752 12.109 13.6729 11.9681 13.532L7.99997 9.56261L4.0306 13.5307C3.8897 13.6716 3.69861 13.7508 3.49935 13.7508C3.30009 13.7508 3.10899 13.6716 2.9681 13.5307C2.8272 13.3898 2.74805 13.1987 2.74805 12.9995C2.74805 12.8002 2.8272 12.6091 2.9681 12.4682L6.93747 8.50011L2.96935 4.53073C2.82845 4.38984 2.7493 4.19874 2.7493 3.99948C2.7493 3.80023 2.82845 3.60913 2.96935 3.46823C3.11024 3.32734 3.30134 3.24818 3.5006 3.24818C3.69986 3.24818 3.89095 3.32734 4.03185 3.46823L7.99997 7.43761L11.9693 3.46761C12.1102 3.32671 12.3013 3.24756 12.5006 3.24756C12.6999 3.24756 12.891 3.32671 13.0318 3.46761C13.1727 3.60851 13.2519 3.7996 13.2519 3.99886C13.2519 4.19812 13.1727 4.38921 13.0318 4.53011L9.06247 8.50011L13.0306 12.4695Z"
            fill="#292D33"
            style="fill: #292d33; fill: color(display-p3 0.1608 0.1765 0.2); fill-opacity: 1"
          />
        </svg>
      </div>
    </div>
    <div class="h-px self-stretch bg-gray-200"></div>
    <div class="flex flex-col items-center justify-start gap-4 self-stretch px-6 pt-3 pb-4">
      <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">
        {{ content }}
      </div>
    </div>

    <div class="px-6 pb-4 w-full flex">
      <div class="flex h-10 items-center justify-center gap-1 self-stretch rounded-md border border-gray-300 bg-white px-3 hover:bg-gray-300 hover:cursor-pointer" @click="close">
        <div class="text-sm font-medium leading-tight text-zinc-800">{{$t('close')}}</div>
      </div>
    </div>
  </dialog>
</template>

<script>
export default {
  props: {
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.open();
  },
  methods: {
    close() {
      this.$refs.dialog.close();
      this.emitClose();
    },
    emitClose() {
      setTimeout(() => {
        this.$emit("close");
      }, 240);
    },
    open() {
      this.$refs.dialog.showModal();
      if (this.closeOnBackdrop) {
        this.$refs.dialog.addEventListener("click", (e) => this.backdropClick(e));
      }
    },
    backdropClick(event) {
      event.target === this.$refs.dialog && this.close();
    },
  },
};
</script>

<style>

dialog::backdrop {
    @apply bg-zinc-800 bg-opacity-20;
}

dialog[open] {
  animation-name: fade-in;
}

dialog[open]::backdrop {
    animation: fade-in .25s ease-out;
}



@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


</style>
