<template>
  <div class="flex flex-col gap-6">
    <NumberStep :number="1" :title="'Tilføj Homerunner i AppStore'">
      <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">Gå til Shopify App Store og tilføj Homerunner til din webshop.</div>

      <a href="https://apps.shopify.com/coolrunner-app" target="_blank" class="mt-2 flex gap-1">
        <div class="text-sm font-normal leading-tight text-blue-600">Gå til Shopify Appstore</div>
        <div class="flow-external relative h-4 w-4"></div>
      </a>
    </NumberStep>

    <NumberStep :number="2" :title="'Opsæt Homerunner i Shopify'">
      <div class="flex flex-col gap-2">
        <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">For at skabe koblingen mellem Homerunner og Shopify, skal du verificere shoppen ved at bruge nedenstående aktiveringskode.</div>

        <div class="text-center">
          <div class="text-center text-[32px] font-semibold leading-[46.40px] text-zinc-800">{{ code }}</div>
        </div>
        <div class="text-center text-xs font-normal leading-[17.40px] text-zinc-500">Aktiveringskoden udløber automatisk {{ formattedExpiration }}. <br />Koden skal derfor være brugt inden.</div>
      </div>
    </NumberStep>
    <NumberStep :number="3" :title="'Gå til Homerunner'">
      <div class="flex flex-col gap-2">
        <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">
          Herefter logger du ind på din konto. Nu kan du teste om forbindelsen er sat op, ved at sende ordre igennem. Er den sat op, vil der blive oprettet forsendelser. Hvis ikke, er du velkommen til at kontakte Homerunner.
        </div>
        <span
          >Kontakt Homerunner på
          <a href="mailto:kundeservice@homerunner.com" target="_blank" class="mt-2 flex gap-1">
            <div class="text-sm font-normal leading-tight text-blue-600">it@homerunner.com</div>
            <div class="flow-external relative h-4 w-4"></div> </a
        ></span>
      </div>
    </NumberStep>
  </div>
</template>

<script>
import NumberStep from "../../components/NumberStep.vue";

export default {
  components: {
    NumberStep,
  },
  props: {
    code: {
      type: String,
      default: "",
    },
    expiration: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedExpiration: function () {
      return this.$moment(this.expiration).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>
