<template>
  <div>
    <BackButton @click="() => $emit('back')" />

    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.manual_setup.title", { title: shopType.title }) }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.manual_setup.description", { title: shopType.title }) }}</div>
    <div class="my-8 flex flex-col gap-8">
      <NumberStep :number="1" :title="$t('flow.shop.manual_setup.step_1.title')">
        <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">
          {{ $t("flow.shop.manual_setup.step_1.description", { title: shopType.title }) }}
          <a :href="shopType.link" target="_blank" class="mt-2 inline-flex gap-1">
            <div class="text-sm font-normal leading-tight text-blue-600">her</div>
            <div class="flow-external relative h-4 w-4"></div>
          </a>
        </div>
      </NumberStep>

      <NumberStep :number="2" :title="$t('flow.shop.manual_setup.step_2.title')">
        <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">{{$t('flow.shop.manual_setup.step_2.description', {title: shopType.title})}}</div>

        <RouterLink :to="{name: 'integrations_api'}" target="_blank" class="mt-2 flex gap-1">
          <div class="text-sm font-normal leading-tight text-blue-600">{{$t('show_my_api_key')}}</div>
          <div class="flow-external relative h-4 w-4"></div>
        </RouterLink>
      </NumberStep>

      <NumberStep :number="3" :title="$t('flow.shop.manual_setup.step_3.title')">
        <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">{{$t('flow.shop.manual_setup.step_3.description', {title: shopType.title})}}</div>

        <a :href="shopType.supportLink" target="_blank" class="mt-2 flex gap-1">
          <div class="text-sm font-normal leading-tight text-blue-600">{{$t('flow.shop.manual_setup.step_3.link', {title: shopType.title})}}</div>
          <div class="flow-external relative h-4 w-4"></div>
        </a>
      </NumberStep>
    </div>

    <CoreButton type="coolrunner" class="w-full" @click="() => $emit('continue', 'ActivateCarrier')">{{ `${this.$t("continue_with")} Shopify` }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import NumberStep from "../components/NumberStep.vue";
import BackButton from "../components/BackButton.vue";
import { RouterLink } from "vue-router";
export default {
  name: "ShopStep3",
  components: {
    CoreButton,
    NumberStep,
    BackButton,
    RouterLink
},
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopType: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
