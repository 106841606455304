<template>
  <div>
    <div class="inline-flex h-8 items-center justify-center gap-1 rounded-md border border-gray-300 bg-white px-3 hover:cursor-pointer hover:bg-gray-200" @click="() => $emit('back')">
      <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t("flow.go_back") }}</div>
    </div>
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.plan.step_3.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.plan.step_3.description") }}</div>
    <div class="mt-6 text-sm font-normal leading-normal" style="color: #282d34">{{ $t("flow.plan.step_3.sub_description") }}</div>
    <div class="my-6 flex flex-col gap-4">
      <AutoBalance ref="autobalanceRef" :form="form" @completed="continueForm"></AutoBalance>

    </div>

    <CoreButton type="coolrunner" :disabled="(form.auto_balance.enabled && form.auto_balance.amount < 500)" class="w-full" @click="submit">{{ form.auto_balance.enabled ? $t('flow.plan.continue_to_pay_x_amount', {amount: form.auto_balance.amount || 500}) : $t("flow.plan.step_3.continue_without") }}</CoreButton>
  </div>
</template> 

<script>
import CoreButton from "@/components/core/Button.vue";
import AutoBalance from "./components/AutoBalance.vue";
export default {
  name: "PlanStep3",
  components: {
    CoreButton,
    AutoBalance
},
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    autobalanceRef: null,
  }),

  methods: {
    submit: function () {
      if(!this.form.auto_balance.enabled) {
        this.continueForm()
      }
      else this.$refs.autobalanceRef.addCard()

    },
    continueForm: function () {
      this.$emit("continue");
    },
  },
};
</script>
