<template>
  <div class="flow-wrapper">
    <NameAndUrl v-if="step == 'NameAndUrl'" :form="form" @continue="() => forwardStep('ShopType')" @back="goBack" />
    <ShopType v-else-if="step == 'ShopType'" :form="form" :shop-types="shopTypes" :manual-shop-types="manualShopTypes" @continue="forwardStep" @back="goBack" />
    <StepShopifyPlan v-else-if="step == 'ShopifyPlan'" :form="form" :shop-types="shopTypes" :manual-shop-types="manualShopTypes" @continue="forwardStep" @back="goBack" />
    <StepShopifyNoCCS v-else-if="step == 'ShopifyNoCCS'" :form="form" @continue="forwardStep" @back="goBack" />
    <ActivateCarrier v-else-if="step == 'ActivateCarrier'" :form="form" :carriers="carriers" @continue="forwardStep" @back="goBack" />
    <ManualSetup v-else-if="step == 'ManualSetup'" :form="form" :shop-type="selectedShopType" @continue="forwardStep"  @back="goBack"/>
    <FreeFreight v-else-if="step == 'FreeFreight'" :form="form" :carriers="selectedCarriersWithCps" @continue="forwardStep" @back="goBack" />
    <Review v-else-if="step == 'Review'" :form="form" :carriers="selectedCarriersWithCps" :selected-shop-type="selectedShopType" @continue="completeFlow" @back="goBack" />
  </div>
</template>

<script>
import FlowMixin from "../FlowMixin.vue";
import NameAndUrl from "./NameAndUrl.vue";
import ShopType from "./ShopType.vue";
import StepShopifyPlan from "./StepShopifyPlan.vue";
import StepShopifyNoCCS from "./StepShopifyNoCCS.vue";
import ActivateCarrier from "./ActivateCarrier.vue";
import ManualSetup from "./ManualSetup.vue";
import FreeFreight from "./FreeFreight.vue";
import Review from "./Review.vue";

export default {
  name: "Shop",
  components: { ShopType, StepShopifyNoCCS, StepShopifyPlan, NameAndUrl, ActivateCarrier, ManualSetup, FreeFreight, Review },
  mixins: [FlowMixin],
  data: () => ({
    flow: "shop",
    firstStep: "NameAndUrl",
    loading: true,
    form: {
      fields: {
        name: "",
        shop_url: "",
      },
      type: "",
      selectedCarriers: [],
      freeCps: [],
      cps: [],
      freeFreightLimit: 0,
      shippingMethods: [],
      shopifyPlan: null,
      paymentFrequency: null,
    },
    manualShopTypes: {
      dandomain: {
        title: "DanDomain",
        image: "https://a.storyblok.com/f/120110/x/e3743fd651/dandomain.svg/m/",
        link: "https://dandomain.dk/webshop/apps/app/CoolRunner",
        supportLink: "https://support.dandomain.dk/dandomain-webshop-hjaelpeunivers/",
      },
      ideal_shop: {
        title: "Ideal Shop",
        image: "https://a.storyblok.com/f/120110/200x39/91747a625f/ideal_shop.png/m/",
        link: "https://www.ideal.shop/integrations/homerunner",
        supportLink: "https://www.ideal.shop/kontakt",
      },
      smartweb: {
        title: "SmartWeb",
        image: "https://a.storyblok.com/f/120110/482x104/4252e0bd8d/smartweb.png/m/",
        link: "https://www.smartweb.dk/coolrunner/ ",
        supportLink: "https://www.smartweb.dk/support/",
      },
      hostedshop: {
        title: "HostedShop",
        image: "https://a.storyblok.com/f/120110/104x19/b4d6440522/hostedshop.svg",
        link: "https://help.hostedshop.dk/coolrunner/",
        supportLink: "https://help.hostedshop.dk/",
      },
      prestashop: {
        title: "PrestaShop",
        image: "https://a.storyblok.com/f/120110/114x13/fba7008ffb/prestashop.svg",
        link: "https://prestashop.butikki.dk/da/forside/29-coolrunner.html",
        supportLink: "https://prestashop.butikki.dk/da/",
      },
    },
    carriers: [
      {
        slug: "bring",
        name: "Bring",
        cps: [
          {
            name: "Bring Hjemmelevering",
            slug: "bring_private_delivery",
          },
          {
            name: "Bring Pakkeshoplevering",
            slug: "bring_private_droppoint",
          },
        ],
      },
      {
        slug: "dao",
        name: "DAO",
        cps: [
          {
            name: "DAO Hjemmelevering",
            slug: "dao_private_delivery",
          },
          {
            name: "DAO Pakkeshoplevering",
            slug: "dao_private_droppoint",
          },
        ],
      },
    ],
  }),
  computed: {
    shopTypes: function () {
      const { custom, ...types } = this.$store.state.shopSettings;
      return types;
    },
    selectedCarriersWithCps: function () {
      return this.carriers.filter((carrier) => this.form.selectedCarriers.includes(carrier.slug));
    },
    selectedShopType: function () {
      const combinedShopTypes = { ...this.shopTypes, ...this.manualShopTypes };
      return combinedShopTypes[this.form.type];
    },
  },
};
</script>

<style>
.flow-wrapper .el-checkbox {
  display: flex;
}

.flow-wrapper .el-checkbox__label p {
  margin-top: -5px;
  text-wrap: wrap;
  line-height: 21.75px;
}

.flow-wrapper .el-checkbox__label a {
  text-decoration: underline;
}
</style>
