<template>
  <div class="flex h-16 items-center justify-start gap-4 rounded-xl border border-gray-200 p-4 cursor-pointer" @click="() => $emit('click')">
    <svg v-if="active" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="20" height="20" rx="5" fill="#157EFB" style="fill: #157efb; fill: color(display-p3 0.0824 0.4941 0.9843); fill-opacity: 1" />
      <path
        d="M16.8034 7.40695L8.4701 15.7403C8.39752 15.8131 8.31128 15.8709 8.21632 15.9103C8.12135 15.9498 8.01954 15.9701 7.91672 15.9701C7.8139 15.9701 7.71208 15.9498 7.61712 15.9103C7.52216 15.8709 7.43591 15.8131 7.36333 15.7403L3.7175 12.0945C3.64483 12.0218 3.58718 11.9355 3.54785 11.8406C3.50852 11.7456 3.48828 11.6438 3.48828 11.5411C3.48828 11.4383 3.50852 11.3365 3.54785 11.2416C3.58718 11.1466 3.64483 11.0604 3.7175 10.9877C3.79017 10.915 3.87645 10.8574 3.9714 10.818C4.06635 10.7787 4.16811 10.7585 4.27089 10.7585C4.37366 10.7585 4.47543 10.7787 4.57038 10.818C4.66533 10.8574 4.7516 10.915 4.82427 10.9877L7.91737 14.0808L15.698 6.30149C15.8447 6.15472 16.0438 6.07227 16.2514 6.07227C16.4589 6.07227 16.658 6.15472 16.8047 6.30149C16.9515 6.44825 17.034 6.64731 17.034 6.85487C17.034 7.06243 16.9515 7.26149 16.8047 7.40826L16.8034 7.40695Z"
        fill="white"
        style="fill: white; fill: white; fill-opacity: 1"
      />
    </svg>
    <svg v-else width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.5" width="18" height="18" rx="4" fill="white" style="fill: white; fill: white; fill-opacity: 1" />
      <rect x="1" y="1.5" width="18" height="18" rx="4" stroke="#E5E6E7" style="stroke: #e5e6e7; stroke: color(display-p3 0.898 0.902 0.9059); stroke-opacity: 1" stroke-width="2" />
    </svg>

    <div class="relative flex h-8 w-[100px] items-center justify-center gap-2.5 bg-white">
      <img :src="`https://api.coolrunner.dk/images/carriers/${carrier.slug}.png?h=64`" class="absolute h-full w-full object-contain px-2" />
    </div>
    <div class="w-full text-base font-medium leading-none text-zinc-800">{{ carrier.name }}</div>
  </div>
</template>

<script>
export default {
  name: "CarrierPicker",
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
