<template>
  <div>
    <BackButton @click="() =>$emit('back')" />
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.step_2.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.step_2.description") }}</div>
    <div class="my-8 flex flex-col gap-8">
      <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-3">
        <ShopPicker v-for="([key, st], idx) in Object.entries(shopTypes)" :key="key" :type="st" :selected="form.type === key" class="col-span-2" :class="{
          'col-start-2': idx === 3,
          'col-start-4': idx === 4,
        }" @select="form.type = key" />
      </div>
      <div class="flex w-full flex-col gap-3">
        <div class="self-stretch text-center text-sm font-normal leading-tight text-zinc-500">{{ $t("flow.shop.step_2.manually_configure") }}</div>
        <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-3 self-stretch">
          <ShopPicker v-for="([key, st], idx) in Object.entries(manualShopTypes)" :key="key" manual :type="st" :selected="form.type === key" class="col-span-2" :class="{
          'col-start-2': idx === 3,
          'col-start-4': idx === 4,
        }" @select="form.type = key"/>
        </div>
      </div>
    </div>

    <CoreButton type="coolrunner" class="w-full" :disabled="!form.type" @click="continueForm">{{ $t("continue") }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import ShopPicker from "./components/ShopPicker.vue";
import BackButton from "../components/BackButton.vue";
export default {
  name: "ShopStep2",
  components: {
    CoreButton,
    ShopPicker,
    BackButton,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopTypes: {
      type: Object,
      default: () => ({}),
    },
    manualShopTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    continueForm: function () {
      if(this.form.type === 'shopify') {
        this.$emit("continue", 'ShopifyPlan');
      } else if (Object.keys(this.manualShopTypes).includes(this.form.type)){
        this.$emit("continue", 'ManualSetup');
      }
      else {
        this.$emit("continue", 'ActivateCarrier');
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
