<template>
  <div
    class="flex w-full flex-col items-start justify-center rounded-md border bg-white hover:cursor-pointer"
    :class="[
      {
        'border-coolrunner-500 ring-1 ring-coolrunner-500': selected,
        'border-gray-200': !selected,
      },
      containerClasses,
    ]"
    @click="$emit('click')"
  >
    <div class="text-sm font-normal leading-tight text-zinc-500">{{ description }}</div>
    <div class="inline-flex w-full items-center justify-between gap-3">
      <div class="basis-0 font-medium leading-[29px] text-zinc-800" :class="titleClasses">{{ title }}</div>
      <div v-if="selected" class="flow-checkmark relative h-6 w-6 rounded-full"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
  },
  computed: {
    titleClasses: function () {
      return {
        "text-lg": this.size === "default",
        "text-base": this.size === "small",
      };
    },
    containerClasses: function () {
      return {
        "px-6 py-4": this.size === "default",
        "px-4 py-4": this.size === "small",
      };
    },
  },
};
</script>
