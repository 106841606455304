<script>
export default {
  data: () => ({
    flow: "",
    firstStep: "",
    step: "",
    history: [],
  }),
  computed: {},
  mounted() {
    //get form from localstorage
    let form = localStorage.getItem(this.flow + "_onboarding_form");
    if (form) this.form = JSON.parse(form);
    this.forwardStep(this.firstStep);
  },
  methods: {
    forwardStep: function (step) {
      if (this.step) this.history.push(this.step);
      this.step = step;

      //save form to localstorage
      if (this.form) localStorage.setItem(this.flow + "_onboarding_form", JSON.stringify(this.form));
    },
    goBack: function () {
      this.step = this.history.pop();
    },
    completeFlow: function () {
      this.$emit("flow:completed");
      localStorage.removeItem(this.flow + "_onboarding_form");
    },
    halt: function () {
      this.$emit("flow:halt");
      localStorage.removeItem(this.flow + "_onboarding_form");
    },
  },
};
</script>
