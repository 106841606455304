<template>
  <div>
    <div class="mt-3 h-12 w-12">
      <img :src="selectedShopType.image" />
    </div>
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.review.title", { shop_type: selectedShopType.title }) }}</div>
    <div class="my-6">
      <template v-if="shop">
      <ShopifyReview v-if="shop.platform == 'shopify'" :code="shop.activation_code" :expiration="shop.activation_expires_at" :link="shop.download_url" />
      <Magento1 v-else-if="shop.platform == 'magento-1'" :code="shop.activation_code" :expiration="shop.activation_expires_at" :link="shop.download_url" />
      <Magento2  v-else-if="shop.platform == 'magento-2'" :code="shop.activation_code" :expiration="shop.activation_expires_at" :link="shop.download_url" />
      <WooCommerce  v-else-if="shop.platform == 'woocommerce'" :code="shop.activation_code" :expiration="shop.activation_expires_at" :link="shop.download_url" />
      <Shopware  v-else-if="shop.platform == 'shopware'" :code="shop.activation_code" :expiration="shop.activation_expires_at" :link="shop.download_url" />
    </template>
    </div>

    

    <CoreButton v-if="shop" type="coolrunner" class="w-full" @click="continueForm">{{ `${this.$t("flow.review.complete_button")}` }}</CoreButton>
    
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import ShopifyReview from "./Reviews/Shopify.vue";
import Loader from '@/mixins/Loader';
import Magento1 from "./Reviews/Magento1.vue";
import Magento2 from "./Reviews/Magento2.vue";
import WooCommerce from "./Reviews/WooCommerce.vue";
import Shopware from "./Reviews/Shopware.vue";

export default {
  name: "Review",
  components: {
    CoreButton,
    ShopifyReview,
    Magento1,
    Magento2,
    WooCommerce,
    Shopware
},
  mixins: [Loader],
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    selectedShopType: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (vm) => ({
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: vm.$t("flow.shop.creating"),
      customClass: null
    },
        shop: null,
  }),
  mounted() {
    this.createShop();
  },

  methods: {
    continueForm: function () {
      this.$emit("continue");
    },
    createShop: function () {
      this.loading = true;
      const body = {
        shop: {
          data: this.form.fields,
          type: this.form.type,
        },
        checkout: this.form.shippingMethods?.length
          ? {
              name: "Default Checkout",
              description: "Default Checkout",
            }
          : null,
        shipping_methods: this.form.shippingMethods?.length ? this.form.shippingMethods : null,
      };
      return this.$post(`/boarding/shop`, body, {
      }).then((res) => {
        this.shop = res;
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
