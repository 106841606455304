<template>
  <div>
    <div class="inline-flex h-8 items-center justify-center gap-1 rounded-md border border-gray-300 bg-white px-3 hover:cursor-pointer hover:bg-gray-200" @click="() => $emit('back')">
      <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t("flow.go_back") }}</div>
    </div>
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.plan.step_4.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.plan.step_4.description") }}</div>
    <div class="my-6 flex flex-col gap-4">
      <div class="flex flex-col items-start justify-start rounded-md border border-gray-200 bg-white">
        <div class="inline-flex items-center justify-start gap-6 self-stretch px-6 py-4">
          <div class="text-base font-medium leading-normal text-zinc-800">{{ $t('flow.plan.step_4.pickup_unit', {unit: $t(`flow.plan.step_2.${form.unit_type}.title`)}) }}</div>
        </div>
        <div class="h-px self-stretch bg-gray-200"></div>
        <div class="flex flex-col items-start justify-center gap-6 self-stretch px-6 py-4">
          <div class="flex  flex-col items-start justify-start gap-2 self-stretch">
            <div class="flex items-start justify-between self-stretch">
              <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t('flow.plan.step_4.dfm_pickup') }}</div>
              <div class="w-[90px] text-right text-sm font-normal leading-tight text-zinc-800">{{$t('free')}}</div>
            </div>
            <div v-if="form.unit_type === 'bag'" class="flex items-start justify-between self-stretch">
              <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t('flow.plan.step_4.plan_size_25') }}</div>
              <div class="w-[90px] text-right text-sm font-normal leading-tight text-zinc-800">{{$t('free')}}</div>
            </div>
          </div>
        </div>

      </div>
      <AutoBalance ref="autobalanceRef" :toggleable="false" :form="form" @completed="continueForm"></AutoBalance>
    </div>

    <CoreButton type="coolrunner" :disabled="form.auto_balance.enabled && form.auto_balance.amount < 500" class="w-full" @click="submit">{{
      $t("flow.plan.continue_to_pay_x_amount", { amount: form.auto_balance.amount || 500 })
    }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import AutoBalance from "./components/AutoBalance.vue";
export default {
  name: "PlanStep4",
  components: {
    CoreButton,
    AutoBalance,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    autobalanceRef: null,
  }),

  methods: {
    submit: function () {
      if (!this.form.auto_balance.enabled) {
        this.continueForm();
      } else this.$refs.autobalanceRef.addCard();
    },
    continueForm: function () {
      this.$emit("continue");
    },
  },
};
</script>
