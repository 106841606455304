<template>
  <div class="flow-wrapper">
    <Step1 v-if="step == 1" :form="form" @continue="step = 2" />
    <Step2 v-else-if="step == 2" :form="form" :countries="countries" @back="step = 1" @submit="submit" />
  </div>
</template>

<script>
import FlowMixin from "../FlowMixin.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";

export default {
  name: "CreateUser",
  components: { Step1, Step2 },
  mixins: [FlowMixin],
  data: () => ({
    flow: "create_user",
    firstStep: 1,
    loading: true,
    form: {
      name: "",
      company_name: "",
      company_vat: "",

      billing_address: {
        name: "",
        street1: "",
        zip_code: "",
        city: "",
        country: "DK",
      },
      shipping_address: {
        name: null,
        street1: null,
        zip_code: null,
        city: null,
        country: null,
      },
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      toc: false,
      "g-recaptcha": null,
    },
    countries: [],
  }),

  mounted() {
    if(!this.step)
      this.step = 1;
    this.fetchCountries();

    //check if url has query params and fill form
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("email")) {
      this.form.email = urlParams.get("email");
    }
  },
  methods: {
    fetchCountries: function () {
      this.loading = true;
      this.$get("https://api.coolrunner.dk/frontend/general/countries")
        .then((response) => {
          Object.entries(response).forEach(([key, value]) => {
            this.countries.push({
              iso: key,
              full_name: value.name,
            });
          });
        })
        .finally((e) => {
          this.loading = false;
        });
    },
    submit: function () {
      this.loading = true;
      
      this.$post("https://auth.homerunner.com/api/account", this.form)
        .then((response) => {
          return this.$store.dispatch("doAuth", { username: this.form.email, password: this.form.password });
        })
        .then(() => {
          this.completeFlow()})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.flow-wrapper .el-checkbox {
  display: flex;
}

.flow-wrapper .el-checkbox__label p {
  margin-top: -5px;
  text-wrap: wrap;
  line-height: 21.75px;
}

.flow-wrapper .el-checkbox__label a {
  text-decoration: underline;
}

.captcha-wrapper {
  margin-top: 14px;
}
</style>
