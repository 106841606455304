<template>
  <div>
    <div class="text-3xl font-semibold">{{ $t("flow.stop.step_1.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.step_1.description") }}</div>
    <div class="my-8 flex flex-col gap-3" >
      <Input v-model="form.fields.shop_url" :error="errors.shop_url" :label="$t('flow.shop.step_1.url')" autocomplete="url" @blur="checkUrl"/>

      <Input v-model="form.fields.name" :label="$t('flow.shop.step_1.name')" autocomplete="organization" />

    </div>

    <CoreButton  type="coolrunner" class="w-full" :disabled="!validated" @click="submit">{{ $t("continue") }}</CoreButton>
    <router-link  class="block w-full text-center text-sm font-medium leading-tight text-blue-500 mt-3 hover:underline hover:cursor-pointer" :to="{name: 'home'}">{{ $t('flow.shop.step_2.no_integration') }}</router-link>

  </div>
</template>

<script>
import Input from "@/components/core/Input.vue";
import CoreButton from "@/components/core/Button.vue";

export default {
  name: "ShopStep1",
  components: {
    Input,
    CoreButton,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errors: {
      shop_url: '',
      name: '',
    }
  }),
  computed: {
    validated: function () {
      return this.form.fields.shop_url && this.form.fields.name && this.urlIsValid;
    },
    urlIsValid: function () {
      //validate this.form.url is a valid url using regex

      const pattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;


  return pattern.test(this.form.fields.shop_url);
    },
  },
  methods: {
    submit: function () {
      this.$emit("continue");
    },
    checkUrl: function () {
      if(!this.urlIsValid && this.form.fields.shop_url && !this.errors.shop_url) {
        this.errors.shop_url = '';
        this.$nextTick(() => {
          this.errors.shop_url = this.$t('errors.url_not_valid');
        })
      }
      else {
        this.errors.shop_url = '';
      }
    },
  },
};
</script>
