<template>
  <div>
    <BackButton @click="() => $emit('back')" />
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.activate_carriers.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.activate_carriers.description") }}</div>

    <div class="mt-8 mb-6 flex flex-col gap-8">
      <div class="inline-flex flex-col items-start justify-start gap-6 rounded-md border border-gray-200 bg-white p-6">
        <div class="flex flex-col items-start justify-start gap-3 self-stretch">
          <div class="self-stretch text-sm font-normal leading-tight text-zinc-800">{{ $t("flow.shop.activate_carriers.title") }}</div>
          <div class="flex flex-col items-stretch gap-2 self-stretch">
            <CarrierPicker v-for="carrier in carriers" :key="carrier.slug" :carrier="carrier" :active="carrierIsSelected(carrier)" @click="() => selectCarrier(carrier)" />
          </div>
        </div>
      </div>
      <div class="inline-flex items-center justify-start gap-2">
        <div class="h-6 w-6 flow-info bg-cover"></div>
        <div class="text-sm font-normal text-zinc-500">{{ $t('flow.shop.activate_carriers.carriers_abroad') }}</div>
      </div>
    </div>

    <CoreButton type="coolrunner" class="w-full" :disabled="!form.selectedCarriers.length" @click="continueForm">{{ `${this.$t("continue")}` }}</CoreButton>

    <div class="mt-6 inline-flex items-start justify-center gap-2 rounded-md border border-gray-200 bg-[#F9F9F9] p-2">
      <div class="text-base font-semibold leading-normal text-black">💡</div>
      <div class="flex-col items-start justify-start gap-0.5">
        <div class="mb-1 text-sm font-semibold leading-tight text-zinc-800">Shopify årlig betaling</div>
        <div class="text-sm font-normal leading-5 text-zinc-500">
          {{ $t("flow.shop.activate_carriers.shopify_yearly_info") }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import BackButton from "../components/BackButton.vue";
import CarrierPicker from "./components/CarrierPicker.vue";
export default {
  name: "ActivateCarrier",
  components: {
    CoreButton,
    BackButton,
    CarrierPicker,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopTypes: {
      type: Object,
      default: () => ({}),
    },
    carriers: {
      type: Array,
      default: () => ([]),
    }
  },

  methods: {
    continueForm: function () {
      let cps = [];
      this.form.selectedCarriers.forEach((carrierSlug) => {
        let carrier = this.carriers.find((carrier) => carrier.slug === carrierSlug);
        cps = cps.concat(carrier.cps);
      });

      this.form.cps = cps;

      this.$emit("continue", "FreeFreight");
    },
    carrierIsSelected: function (carrier) {
      return this.form.selectedCarriers.includes(carrier.slug);
    },
    selectCarrier: function (carrier) {
      if (this.carrierIsSelected(carrier)) {
        this.form.selectedCarriers = this.form.selectedCarriers.filter((item) => item !== carrier.slug);
      } else {
        this.form.selectedCarriers.push(carrier.slug);
      }
    },
  },
};
</script>
