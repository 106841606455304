<template>
  <div>
    <div
      class="flex items-center justify-start rounded-md border border-gray-200 bg-white px-6 py-3.5"
      :class="{
        'rounded-b-none border-b-0': form.auto_balance.enabled,
      }"
    >
      <div class="flex gap-4 self-stretch">
        <div class="flex items-center justify-between gap-1">
          <div class="flex items-center gap-2 text-base font-medium leading-normal text-zinc-800">
            {{ $t("flow.plan.automatic_balance") }}
            <popper trigger="hover" :options="{ placement: 'right' }">
              <div class="flow-popper popper">
                {{ this.$t("flow.plan.automatic_balance_info") }}
              </div>
              <div slot="reference" class="flow-info relative h-4 w-4"></div>
            </popper>
          </div>

          <label v-if="toggleable" for="auto-balance-toggle" class="toggle-switch cursor-pointer">
            <div class="relative">
              <input id="auto-balance-toggle" v-model="form.auto_balance.enabled" type="checkbox" class="checkbox hidden" />
              <div class="block h-6 w-10 rounded-full bg-[#E5E6E7]"></div>
              <div class="dot absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition"></div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="form.auto_balance.enabled" class="flex flex-col items-center justify-start gap-4 border border-gray-200 bg-white px-6 py-4">
      <div class="self-stretch font-['Inter'] text-sm font-normal leading-tight text-zinc-500">{{ $t("flow.plan.automatic_balance_description") }}</div>
      <fieldset class="inline-flex items-center justify-start gap-6 self-stretch">
        <div v-for="pa in preamounts" :key="pa" class="mb-4 flex items-center">
          <input :id="`pre-amount-${pa}`" v-model="form.auto_balance.amount" type="radio" name="pre-amount" :value="pa" class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300" checked="" />
          <label :for="`pre-amount-${pa}`" class="ml-2 block text-sm font-medium text-gray-900"> {{ pa }} kr. </label>
        </div>
      </fieldset>
      <div class="inline-flex items-center justify-start gap-2.5 self-stretch">
        <Input v-model="form.auto_balance.amount" type="number" :min="500" :label="`${$t('flow.plan.automatic_balance_custom_amount')} (Min. 500kr)`" autocomplete="off" />
      </div>
    </div>
  </div>
</template>

<script>
import Popper from "vue-popperjs";
import Input from "@/components/core/Input.vue";
import Payment from "@/mixins/Payment";

export default {
  name: "AutoBalance",
  components: {
    Popper,
    Input,
  },
  mixins: [Payment],
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    toggleable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    preamounts: [500, 800, 1000],
    loading: false,
    paymentEndpoint: "account/payment",
  }),
  watch: {
    "form.auto_balance.enabled": function (val) {
      if (!val) {
        this.form.auto_balance.amount = 0;
      } else {
        this.form.auto_balance.amount = 500;
      }
    },
    "toggleable": {
      handler: function (val) {
        if (!val) {
          this.form.auto_balance.enabled = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    addCard: function () {
      this.loading = true;
      this.$put(`${this.paymentEndpoint}/card`, {
        final_url: this.landing_page.subscribed,
      })
        .then((response) => {
          let payment_window = this.popup(response.redirect_url);
          this.$on(`payment-${payment_window}:success`, () => {
           // this.setupBalance();
          });
        })
        .catch((e) => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning",
          });
          this.loading = false;
        });
    },
    setupBalance: function () {
      const data = {
        autofill: true,
        autofill_threshold: 50,
        autofill_amount: this.form.auto_balance.amount,
      };
      this.$put(`${this.paymentEndpoint}/balance/autofill`, data)
        .then((response) => {
          this.$notification({
            title: this.$t("saved_correctly"),
          });
        })
        .catch((e) => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning",
          });
        })
        .finally(() => {
          this.loading = false
          this.$emit("completed");
          });
    },
  },
};
</script>

<style>
.flow-wrapper input[type="checkbox"]:checked ~ .block {
  @apply bg-green-500 transition-colors;
}
.flow-wrapper input[type="checkbox"] ~ .block {
  background: #e5e6e7;
}

.flow-wrapper .toggle-switch .dot {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}
.flow-wrapper input[type="radio"] {
  appearance: auto;
}
.flow-wrapper input[type="radio"]:checked ~ .block {
  @apply bg-transparent;
}
.flow-wrapper input[type="radio"] ~ .block {
  @apply bg-transparent;
}
</style>
