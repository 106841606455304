<template>
  <div>
    <div class="inline-flex h-8 items-center justify-center gap-1 rounded-md border border-gray-300 bg-white px-3 hover:cursor-pointer hover:bg-gray-200" @click="() => $emit('back')">
      <div class="text-sm font-medium leading-tight text-zinc-800">{{ $t("flow.go_back") }}</div>
    </div>
    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.create_user.step_2.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.create_user.step_2.description") }}</div>
    <div class="mt-8 flex flex-col gap-3">
      <Input v-model="form.company_vat" :label="$t('company_vat')" />
      <Input v-model="form.company_name" :label="$t('company_name')" autocomplete="organization" />

      <div class="flex flex-col gap-3">
        <div class="mt-2 text-sm font-normal leading-tight text-zinc-800">{{ $t("company_address") }}</div>
        <Input v-model="form.billing_address.name" :label="$t('name')" />
        <Input v-model="form.billing_address.street1" :label="$t('street')" autocomplete="street-address" />
        <div class="flex gap-2">
          <div style="flex: 1"><Input v-model="form.billing_address.zip_code" :label="$t('abrreviations.zip_code')" autocomplete="postal-code" /></div>
          <div style="flex: 3"><Input v-model="form.billing_address.city" :label="$t('city')" autocomplete="address-level2" /></div>
        </div>
        <CountrySelect v-model="form.billing_address.country" :data-override="countries" :label="$t('country')" class="w-full" style="max-width: 100%" />
      </div>
      <div class="mt-3">
        <CoreCheckbox :value="companyAddressSameAsWarehouse" @change="(val) => (companyAddressSameAsWarehouse = val)">
          {{ $t("flow.create_user.step_2.company_warehouse_same") }}
        </CoreCheckbox>
      </div>

      <transition mode="out-in" name="fade">
        <div v-if="!companyAddressSameAsWarehouse" class="flex flex-col gap-3">
          <div class="mt-2 text-sm font-normal leading-tight text-zinc-800">{{ $t("warehouse_address") }}</div>
          <Input v-model="form.shipping_address.name" :label="$t('name')" />
          <Input v-model="form.shipping_address.street1" :label="$t('street')" autocomplete="street-address" />
          <div class="flex gap-2">
            <div style="flex: 1"><Input v-model="form.shipping_address.zip_code" :label="$t('abrreviations.zip_code')" autocomplete="postal-code" /></div>
            <div style="flex: 3"><Input v-model="form.shipping_address.city" :label="$t('city')" autocomplete="address-level2" /></div>
          </div>
          <CountrySelect v-model="form.shipping_address.country" :data-override="countries" :label="$t('country')" class="w-full" style="max-width: 100%" />
        </div>
      </transition>
    </div>

    <div class="mt-6">
      <CoreButton type="coolrunner" class="w-full" :disabled="!canContinue" @click="executeCaptch">{{ $t("flow.create_user.step_2.finish") }}</CoreButton>
    </div>
    <vue-recaptcha ref="recaptcha" class="captcha-wrapper" sitekey="6LdZESgoAAAAAFDYxDehoO3490alaR0gwdaiZYfw" size="invisible" @verify="submit"></vue-recaptcha>
    <p class="mt-8 text-xs" style="color: #6d7276">This site is protected by reCAPTCHA and the Google <a class="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
  </div>
</template>

<script>
import Input from "@/components/core/Input.vue";
import CoreCheckbox from "@/components/core/Checkbox.vue";
import CoreButton from "@/components/core/Button.vue";
import CountrySelect from "@/components/core/Select/Country.vue";
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: "CreateUserStep1",
  components: {
    VueRecaptcha,
    Input,
    CoreCheckbox,
    CoreButton,
    CountrySelect,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    companyAddressSameAsWarehouse: true,
  }),
  computed: {
    canContinue: function () {
      return (
        this.form.company_vat && 
        this.form.company_name &&
        this.form.billing_address.name && 
        this.form.billing_address.street1 &&
        this.form.billing_address.zip_code &&
        this.form.billing_address.city &&
        this.form.billing_address.country &&
        (this.companyAddressSameAsWarehouse || (this.form.shipping_address.name && this.form.shipping_address.street1 && this.form.shipping_address.zip_code && this.form.shipping_address.city && this.form.shipping_address.country))
      );
    },
  },
  watch: {
    companyAddressSameAsWarehouse: function (val) {
      if (!val) {
        // loop through all keys in billing_address and set them to empty string
        Object.keys(this.form.shipping_address).forEach((key) => {
          if (key === "country") this.form.shipping_address[key] = "DK";
          else this.form.shipping_address[key] = "";
        });
      }
    },
  },
  methods: {
    executeCaptch: function () {
      this.$refs.recaptcha.execute();
    },
    submit: function (token) {
      this.form['g-recaptcha'] = token;
      if (this.companyAddressSameAsWarehouse) {
        this.form.shipping_address = { ...this.form.billing_address };
      }

      this.$emit("submit");
    },
    
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
