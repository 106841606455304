var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full flex-col items-start justify-center rounded-md border bg-white hover:cursor-pointer",class:[
    {
      'border-coolrunner-500 ring-1 ring-coolrunner-500': _vm.selected,
      'border-gray-200': !_vm.selected,
    },
    _vm.containerClasses,
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"text-sm font-normal leading-tight text-zinc-500"},[_vm._v(_vm._s(_vm.description))]),_c('div',{staticClass:"inline-flex w-full items-center justify-between gap-3"},[_c('div',{staticClass:"basis-0 font-medium leading-[29px] text-zinc-800",class:_vm.titleClasses},[_vm._v(_vm._s(_vm.title))]),(_vm.selected)?_c('div',{staticClass:"flow-checkmark relative h-6 w-6 rounded-full"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }