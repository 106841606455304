<template>
  <div class="flow-wrapper">
    <Step1 v-if="step == 1" :form="form" @continue="continueForm" />
    <Step2 v-else-if="step == 2" :form="form" @back="step = 1" @continue="continueForm" />
    <Step3 v-else-if="step == 3" :form="form" @back="goBack" @continue="submit" />
    <Step4 v-else-if="step == 4" :form="form" @back="step = 2" @continue="submit" />
  </div>
</template>

<script>
import FlowMixin from "../FlowMixin.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import "vue-popperjs/dist/vue-popper.css";
import * as auth from  "../../../utils/auth";
import { setCookies } from "../../../store";

export default {
  name: "Plan",
  components: { Step1, Step2, Step3, Step4 },
  mixins: [FlowMixin],
  data: () => ({
    flow: "plan",
    firstStep: 1,
    form: {
      plan: "hand_in",
      unit_type: "bag",
      auto_balance: {
        enabled: false,
        amount: 500,
      },
    },
  }),
  computed: {
    account: function () {
      return this.$store.state.account;
    },
  },
  mounted() {
    if(!auth.loggedIn()) {
      this.halt();
    }
  },
  methods: {
    continueForm: function (count = 1) {
      this.step = count + parseInt(this.step);
    },
    goBack: function () {
      if (this.form.plan == "hand_in") this.step = 1;
      else this.step = 2;
    },
    submit: function () {
      let delivery_method = this.form.plan == "hand_in" ? "c2c" : "dfm";

      this.$post(
        `/delivery-methods`,
        { delivery_method: delivery_method, customer_id: this.account.id },
        {
          failSilently: true,
        }
      )
        .then((response) => {
          if (delivery_method == "dfm")
            return this.$post(
              `/boarding/unit-saldo`,
              { unit_type: this.form.unit_type },
              {
                failSilently: true,
              }
            );
        })
        .then((response) => {
          this.$notification({
            title: this.$t("success"),
            type: "success",
          });

          const nextYearInSec = 365 * 24 * 60 * 60;
          setCookies("frontend-version", 'old', nextYearInSec);
          return this.$store.dispatch("loadAccount");
        })
        .then(() => {
          this.completeFlow();
        })
        .catch((e) => {
          this.$notification({
            title: this.$t("error"),
            text: e.response.data.status,
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.flow-wrapper .el-checkbox {
  display: flex;
}

.flow-wrapper .el-checkbox__label p {
  margin-top: -5px;
  text-wrap: wrap;
  line-height: 21.75px;
}

.flow-wrapper .el-checkbox__label a {
  text-decoration: underline;
}
</style>
