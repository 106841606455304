<template>
  <div>
    <BackButton @click="() => $emit('back')" />

    <div class="mt-2 text-3xl font-semibold">{{ $t("flow.shop.step_4.title") }}</div>
    <div class="text-base font-normal leading-normal" style="color: #6d7276">{{ $t("flow.shop.step_4.description") }}</div>

    <div class="my-8 flex flex-col gap-8">
      <NumberStep :number="1" title="Navigér til “Indstillinger” i dit Shopify-administrationsmodul"> </NumberStep>

      <NumberStep :number="2" title="Tilføj dine ønskede forsendelsesmetoder"> </NumberStep>

      <NumberStep :number="3" title="Gå til Homerunner-appen i dit Shopify-administrationsmodul og map leveringsmetoder."> </NumberStep>

      <div><span class="font-['Inter'] text-sm font-normal leading-tight text-zinc-800">Vi har lavet en guide, som detaljeret viser hvordan det gøres. </span><a class="font-['Inter'] text-sm font-normal leading-tight text-blue-500 underline" href="https://a.storyblok.com/f/120110/x/e24a47d728/guide-til-opsaetning-af-transportorer-i-shopify-basic-3.pdf" target="_blank">Download guiden som PDF</a></div>
    </div>

    <CoreButton type="coolrunner" class="w-full" @click="continueForm">{{ `${this.$t("continue_with")} Shopify` }}</CoreButton>
  </div>
</template>

<script>
import CoreButton from "@/components/core/Button.vue";
import NumberStep from "../components/NumberStep.vue";
import BackButton from "../components/BackButton.vue";
export default {
  name: "ShopStep3",
  components: {
    CoreButton,
    NumberStep,
    BackButton,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    shopTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    continueForm: function () {
        this.$emit("continue", "Review");
    },
  },

};
</script>
